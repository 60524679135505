export enum TextTypes {
  requestDemo = 'requestDemo',
  managerProcurement = 'managerProcurement',
  documentation = 'documentation',
  controlQuality = 'controlQuality',
  monitoring = 'monitoring',
  security = 'security',
}

export enum FileNames {
  managerProcurement = 'management.pdf',
  documentation = 'documentation.pdf',
  controlQuality = 'control-quality.pdf',
  monitoring = 'monitoring.pdf',
  security = 'security.pdf',
}
