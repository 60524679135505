import { Link } from 'react-router-dom';

import { ROUTES } from '@shared/config';

import Logo from '/images/logo.svg';
import '@shared/ui/footer/index.css';

export const Footer = () => {
  return (
    <footer className='footer'>
      <div className='nav'>
        <img loading='lazy' className='mb-32' src={Logo} alt='logo' />
      </div>
      <div className='info'>
        <span className='text-14'>© {new Date().getFullYear()} Самолет 10D</span>
        <Link className='text-14' to={ROUTES.policy}>
          Политика приватности
        </Link>
        <Link className='text-14' to={ROUTES.consent}>
          Согласие на обработку персональных данных
        </Link>
      </div>
    </footer>
  );
};
