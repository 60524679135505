import Logo from '/images/logo.svg';

import SecureIcon from '@shared/ui/secondFrame/images/secure-icon.svg';
import QualityIcon from '@shared/ui/secondFrame/images/quality-icon.svg';
import BranchIcon from '@shared/ui/secondFrame/images/branch-icon.svg';
import BoxIcon from '@shared/ui/secondFrame/images/box-icon.svg';
import DocumentationIcon from '@shared/ui/secondFrame/images/documentation-icon.svg';
import '@shared/ui/secondFrame/index.css';

export const SecondFrame = () => {
  return (
    <section className='second-frame mb-128' id='aboutSystem'>
      <div className='logo mb-64'>
        <img loading='lazy' src={Logo} alt='logo' />
      </div>
      <div className='mobile-scroll'>
        <div className='cards-wrapper mb-32'>
          <div className='custom-card'>
            <img
              loading='lazy'
              className='mb-32'
              src={SecureIcon}
              alt='secure control icon'
            />
            <h4 className='mb-8'>Безопасность</h4>
            <p>Система электронных пропусков и контроль численности на объекте</p>
          </div>
          <div className='custom-card big-card primary-card'>
            Комплексное решение для <span>автоматизации</span> стройки
          </div>
          <div className='custom-card'>
            <img
              loading='lazy'
              className='mb-32'
              src={QualityIcon}
              alt='quality control icon'
            />
            <h4 className='mb-8'>Контроль качества</h4>
            <p>Система отслеживания дефектов и интерактивная карта объекта</p>
          </div>
        </div>
        <div className='cards-wrapper'>
          <div className='custom-card'>
            <img
              loading='lazy'
              className='mb-32'
              src={BranchIcon}
              alt='branch control icon'
            />
            <h4 className='mb-8'>ИИ-мониторинг</h4>
            <p>Автоматический анализ хода строительства и отделочных работ</p>
          </div>
          <div className='custom-card big-card'>
            <img loading='lazy' className='mb-32' src={BoxIcon} alt='box control icon' />
            <h4 className='mb-8'>Управление закупками</h4>
            <p>Снабжение строительства: от тендера до приёмки</p>
          </div>
          <div className='custom-card'>
            <img
              loading='lazy'
              className='mb-32'
              src={DocumentationIcon}
              alt='documentation control icon'
            />
            <h4 className='mb-8'>Документация</h4>
            <p>Распределение и контроль рабочей документации</p>
          </div>
        </div>
      </div>
    </section>
  );
};
