import { Layout } from '@shared/ui/layout';
import '@pages/policy/ui/index.css';

export const Policy = () => {
  return (
    <Layout>
      <div className='policy'>
        <h2 className='mb-32 title-40'>
          Политика обработки персональных данных ПАО «ГК «САМОЛЕТ»
        </h2>
        <p>
          1. Общие положения. <br />
          1.1. Настоящая Политика разработана во исполнение требований Федерального закона
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          от 27.07.2006 N 152-ФЗ "О персональных данных" в соответствии с целями, задачами
          и принципами обеспечения безопасности персональных данных (далее — ПДн) ПАО «ГК
          «САМОЛЕТ».
          <br />
          1.2. Цель настоящей Политики: обеспечение обработки ПДн в соответствии с
          требованиями действующего законодательства Российской Федерации в сфере защиты
          ПДн, обеспечение безопасности ПДн, обрабатываемых ПАО «ГК «САМОЛЕТ», от всех
          видов угроз, внешних и внутренних, умышленных и непреднамеренных, минимизация
          ущерба от возможной реализации угроз безопасности ПДн.
          <br />
          2. Основные термины и определения.
          <br />
          2.1 Персональные данные — любая информация, относящаяся к прямо или косвенно
          определенному или определяемому физическому лицу (субъекту персональных данных).
          <br />
          2.2. Информация — сведения (сообщения, данные) независимо от формы их
          представления.
          <br />
          2.3. Оператор — государственный орган, муниципальный орган, юридическое или
          физическое лицо, самостоятельно или совместно с другими лицами организующие и
          (или) осуществляющие обработку персональных данных, а также определяющие цели
          обработки персональных данных, состав персональных данных, подлежащих обработке,
          действия (операции), совершаемые с персональными данными.
          <br />
          2.4. Обработка персональных данных — любое действие (операция) или совокупность
          действий (операций), совершаемые с использованием средств автоматизации или без
          использования таких средств с персональными данными, включая сбор, запись,
          систематизацию, накопление, хранение, уточнение (обновление, изменение),
          извлечение, использование, передачу (распространение, предоставление, доступ),
          обезличивание, блокирование, удаление, уничтожение персональных данных.
          <br />
          2.5. Автоматизированная обработка персональных данных — обработка персональных
          данных с помощью средств вычислительной техники.
          <br />
          2.6. Предоставление персональных данных — действия, направленные на раскрытие
          персональных данных определенному лицу или определенному кругу лиц.
          <br />
          2.7. Распространение персональных данных — действия, направленные на раскрытие
          данных неопределенному кругу лиц.
          <br />
          2.8. Трансграничная передача персональных данных — передача персональных данных
          на территорию иностранного государства органу власти иностранного государства,
          иностранному физическому лицу или иностранному юридическому лицу.
          <br />
          2.9. Блокирование персональных данных — временное прекращение обработки
          персональных данных (за исключением случаев, когда обработка необходима для
          уточнения персональных данных).
          <br />
          2.10. Уничтожение персональных данных — действия, в результате которых
          становится невозможным восстановить содержание персональных данных в
          информационной системе персональных данных и (или) в результате которых
          уничтожаются материальные носители персональных данных.
          <br />
          2.11. Обезличивание персональных данных — действия, в результате которых
          становится невозможным без использования дополнительной информации определить
          принадлежность персональных данных конкретному субъекту персональных данных.
          <br />
          2.12. Информационная система персональных данных — совокупность содержащихся в
          базах данных персональных данных и обеспечивающих их обработку информационных
          технологий и технических средств.
          <br />
          3. Правовые основания обработки персональных данных.
          <br />
          Правовым основанием обработки персональных данных является совокупность
          нормативных правовых актов, во исполнение которых и в соответствии с которыми
          ПАО «ГК «САМОЛЕТ» осуществляет обработку персональных данных, в том числе:
          Конституция Российской Федерации; Трудовой кодекс Российской Федерации;
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Федеральный закон от 30.12.2004 N 214-ФЗ "Об участии в долевом строительстве
          многоквартирных домов и иных объектов недвижимости и о внесении изменений в
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          некоторые законодательные акты Российской Федерации", Устав ПАО «ГК «Самолет»,
          договорные отношения с клиентами, согласие субъектов персональных данных на
          обработку их персональных данных и иные нормативные правовые акты.
          <br />
          4. Принципы и цели обработки персональных данных.
          <br />
          4.1. Обработка персональных данных в ПАО «ГК «САМОЛЕТ» осуществляется с учетом
          необходимости обеспечения защиты прав и свобод субъектов персональных данных, в
          том числе защиты права на неприкосновенность частной жизни, личную и семейную
          тайну, на основе следующих принципов:
          <br />
          - обработка персональных данных осуществляется в ПАО «ГК «САМОЛЕТ» на законной и
          справедливой основе;
          <br />
          - обработка персональных данных ограничивается достижением конкретных, заранее
          определенных и законных целей;
          <br />
          - не допускается обработка персональных данных, несовместимая с целями сбора
          персональных данных;
          <br />
          - не допускается объединение баз данных, содержащих персональные данные,
          обработка которых осуществляется в целях, несовместимых между собой;
          <br />
          - обработке подлежат только персональные данные, которые отвечают целям их
          обработки;
          <br />
          - содержание и объем обрабатываемых персональных данных соответствует заявленным
          целям обработки. Не допускается избыточность обрабатываемых персональных данных
          по отношению к заявленным целям их обработки;
          <br />
          - при обработке персональных данных обеспечиваются точность персональных данных,
          их достаточность, а в необходимых случаях и актуальность по отношению к целям
          обработки персональных данных. ПАО «ГК «САМОЛЕТ» принимаются необходимые меры
          либо обеспечивается их принятие по удалению или уточнению неполных или неточных
          персональных данных;
          <br />
          - хранение персональных данных осуществляется в форме, позволяющей определить
          субъекта персональных данных, не дольше, чем того требуют цели обработки
          персональных данных, если срок хранения персональных данных не установлен
          федеральным законом, договором, стороной которого, выгодоприобретателем или
          поручителем по которому является субъект персональных данных;
          <br />
          - обрабатываемые персональные данные уничтожаются либо обезличиваются по
          достижении целей обработки или в случае утраты необходимости в достижении этих
          целей, если иное не предусмотрено федеральным законом.
          <br />
          4.2. Персональные данные обрабатываются в ПАО «ГК «САМОЛЕТ» в целях:
          <br />
          - обеспечения соблюдения Конституции Российской Федерации, законодательных и
          иных нормативных правовых актов Российской Федерации, локальных нормативных
          актов ПАО «ГК «САМОЛЕТ»;
          <br />
          - осуществления функций, полномочий и обязанностей, возложенных
          законодательством Российской Федерации на ПАО «ГК «САМОЛЕТ»;
          <br />
          - защиты жизни, здоровья или иных жизненно важных интересов субъектов
          персональных данных;
          <br />
          - подготовки, заключения, исполнения и прекращения договоров с клиентами ПАО «ГК
          «САМОЛЕТ»;
          <br />
          - исполнения судебных актов, актов других органов или должностных лиц,
          подлежащих исполнению в соответствии с законодательством Российской Федерации об
          исполнительном производстве;
          <br />
          - осуществления прав и законных интересов ПАО «ГК «САМОЛЕТ» в рамках
          осуществления видов деятельности, предусмотренных Уставом и иными локальными
          нормативными актами ПАО «ГК «САМОЛЕТ», или третьих лиц либо достижения
          общественно значимых целей;
          <br />
          - взаимодействия с потенциальными покупателями на сайте компании в сети
          интернет;
          <br />
          - обеспечения работы программных обеспечений правообладателем которых является
          ПАО «ГК «САМОЛЕТ»;
          <br />
          - в иных законных целях.
          <br />
          5. Перечень субъектов, персональные данные которых обрабатываются в ПАО «ГК
          «САМОЛЕТ».
          <br />
          В ПАО «ГК «САМОЛЕТ» обрабатываются персональные данные следующих категорий
          субъектов персональных данных:
          <br />
          - работники ПАО «ГК «САМОЛЕТ» и их близкие родственники;
          <br />
          - работники дочерних обществ и организаций ПАО «ГК «САМОЛЕТ»;
          <br />
          - физические лица, претендующие на замещение вакантных должностей;
          <br />
          - физические лица (клиенты, потенциальные клиенты), состоящие в договорных или
          иных гражданско-правовых отношениях с ПАО «ГК «САМОЛЕТ»
          <br />
          - другие субъекты персональных данных (для обеспечения реализации целей
          обработки, указанных в разделе 4 Политики).
          <br />
          6. Перечень персональных данных, обрабатываемых в ПАО «ГК «САМОЛЕТ».
          <br />
          Перечень персональных данных, обрабатываемых в ПАО «ГК «САМОЛЕТ», определяется в
          соответствии с законодательством Российской Федерации и локальными нормативными
          актами ПАО «ГК «САМОЛЕТ» с учетом целей обработки персональных данных, указанных
          в разделе 5 Политики.
          <br />
          7. Функции ПАО «ГК «САМОЛЕТ» при осуществлении обработки персональных данных.
          <br />
          ПАО «ГК «САМОЛЕТ» при осуществлении обработки персональных данных:
          <br />
          - принимает меры, необходимые и достаточные для обеспечения выполнения
          требований законодательства Российской Федерации и локальных нормативных актов
          ПАО «ГК «САМОЛЕТ» в области персональных данных;
          <br />
          - принимает правовые, организационные и технические меры для защиты персональных
          данных от неправомерного или случайного доступа к ним, уничтожения, изменения,
          блокирования, копирования, предоставления, распространения персональных данных,
          а также от иных неправомерных действий в отношении персональных данных; -
          назначает лицо, ответственное за организацию обработки персональных данных в ПАО
          «ГК «САМОЛЕТ»;
          <br />
          - издает локальные нормативные акты, определяющие политику и вопросы обработки и
          защиты персональных данных в ПАО «ГК «САМОЛЕТ»;
          <br />
          - осуществляет ознакомление работников ПАО «ГК «САМОЛЕТ», его филиалов и
          представительств, непосредственно осуществляющих обработку персональных данных,
          с положениями законодательства Российской Федерации и локальных нормативных
          актов ПАО «ГК «САМОЛЕТ» в области персональных данных, в том числе требованиями
          к защите персональных данных, и обучение указанных работников;
          <br />
          - публикует или иным образом обеспечивает неограниченный доступ к настоящей
          Политике;
          <br />
          - сообщает в установленном порядке субъектам персональных данных или их
          представителям информацию о наличии персональных данных, относящихся к
          соответствующим субъектам, предоставляет возможность ознакомления с этими
          персональными данными при обращении и (или) поступлении запросов указанных
          субъектов персональных данных или их представителей, если иное не установлено
          законодательством Российской Федерации;
          <br />
          - прекращает обработку и уничтожает персональные данные в случаях,
          предусмотренных законодательством Российской Федерации в области персональных
          данных;
          <br />
          - совершает иные действия, предусмотренные законодательством Российской
          Федерации в области персональных данных.
          <br />
          8. Условия обработки персональных данных в ПАО «ГК «САМОЛЕТ».
          <br />
          8.1. Обработка персональных данных в ПАО «ГК «САМОЛЕТ» осуществляется с согласия
          субъекта персональных данных на обработку его персональных данных, если иное не
          предусмотрено законодательством Российской Федерации в области персональных
          данных.
          <br />
          8.2. ПАО «ГК «САМОЛЕТ» без согласия субъекта персональных данных не раскрывает
          третьим лицам и не распространяет персональные данные, если иное не
          предусмотрено федеральным законом.
          <br />
          8.3. К обработке персональных данных допускаются работники ПАО «ГК «САМОЛЕТ», в
          должностные обязанности которых входит обработка персональных данных.
          <br />
          8.4. Обработка персональных данных, разрешенных субъектом персональных данных
          для распространения, осуществляется с соблюдением запретов и условий,
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          установленных статьей 10.1 Федерального закона "О персональных данных».
          <br />
          8.5. ПАО «ГК «САМОЛЕТ» вправе поручить обработку персональных данных другому
          лицу с согласия субъекта персональных данных на основании заключаемого с этим
          лицом договора. Договор должен содержать перечень действий (операций) с
          персональными данными, которые будут совершаться лицом, осуществляющим обработку
          персональных данных, цели обработки, обязанность такого лица соблюдать
          конфиденциальность персональных данных и обеспечивать безопасность персональных
          данных при их обработке, а также требования к защите обрабатываемых персональных
          данных в соответствии со статьей 19 Федерального закона «О персональных данных».
          <br />
          8.6. ПАО «ГК «САМОЛЕТ» не осуществляет обработку специальных категорий
          персональных данных, касающихся расовой, национальной принадлежности,
          политических взглядов, религиозных или философских убеждений, интимной жизни.
          <br />
          8.7. ПАО «ГК «САМОЛЕТ» не осуществляет обработку биометрических категорий
          персональных данных.
          <br />
          8.8. Используемые в ПАО «ГК «САМОЛЕТ» базы данных информации, содержащей ПДн
          граждан Российской Федерации, располагаются на территории Российской Федерации.
          <br />
          8.9. Сроки хранения персональных данных в ПАО «ГК «САМОЛЕТ» определяются в
          соответствии с законодательством Российской Федерации и нормативными документами
          ПАО «ГК «САМОЛЕТ».
          <br />
          9. Перечень действий с персональными данными и способы их обработки.
          <br />
          9.1. ПАО «ГК «САМОЛЕТ» осуществляет сбор, запись, систематизацию, накопление,
          хранение, уточнение (обновление, изменение), извлечение, использование, передачу
          (распространение, предоставление, доступ), обезличивание, блокирование, удаление
          и уничтожение персональных данных.
          <br />
          9.2. Обработка персональных данных в ПАО «ГК «САМОЛЕТ» осуществляется следующими
          способами:
          <br />
          - неавтоматизированная обработка персональных данных;
          <br />
          - автоматизированная обработка персональных данных с передачей полученной
          информации по информационно-телекоммуникационным сетям или без таковой;
          <br />
          - смешанная обработка персональных данных.
          <br />
          9.3. ПАО «ГК «САМОЛЕТ» не осуществляет трансграничную передачу персональных
          данных.
          <br />
          10. Права субъектов персональных данных.
          <br />
          Субъекты персональных данных имеют право на:
          <br />
          - полную информацию об их персональных данных, обрабатываемых в ПАО «ГК
          «САМОЛЕТ»;
          <br />
          - доступ к своим персональным данным, включая право на получение копии любой
          записи, содержащей их персональные данные, за исключением случаев,
          предусмотренных федеральным законом;
          <br />
          - уточнение своих персональных данных, их блокирование или уничтожение в случае,
          если персональные данные являются неполными, устаревшими, неточными, незаконно
          полученными или не являются необходимыми для заявленной цели обработки;
          <br />
          - отзыв согласия на обработку персональных данных;
          <br />
          - осуществление иных прав, предусмотренных законодательством Российской
          Федерации.
          <br />
          11. Меры, принимаемые ПАО «ГК «САМОЛЕТ» для обеспечения выполнения обязанностей
          оператора при обработке персональных данных.
          <br />
          11.1. Меры, необходимые и достаточные для обеспечения выполнения ПАО «ГК
          «САМОЛЕТ» обязанностей оператора, предусмотренных законодательством Российской
          Федерации в области персональных данных, включают:
          <br />
          - назначение лица, ответственного за организацию обработки персональных данных в
          ПАО «ГК «САМОЛЕТ»;
          <br />
          - принятие локальных нормативных актов и иных документов в области обработки и
          защиты персональных данных;
          <br />
          - организацию обучения и проведение методической работы с работниками ПАО «ГК
          «САМОЛЕТ», занимающими должности, включенные в перечень должностей ПАО «ГК
          «САМОЛЕТ», при замещении которых осуществляется обработка персональных данных;
          <br />
          - получение согласий субъектов персональных данных на обработку их персональных
          данных, за исключением случаев, предусмотренных законодательством Российской
          Федерации;
          <br />
          - обособление персональных данных, обрабатываемых без использования средств
          автоматизации, от иной информации, в частности путем их фиксации на отдельных
          материальных носителях персональных данных, в специальных разделах;
          <br />
          - обеспечение раздельного хранения персональных данных и их материальных
          носителей, обработка которых осуществляется в разных целях и которые содержат
          разные категории персональных данных;
          <br />
          - установление запрета на передачу персональных данных по открытым каналам
          связи, вычислительным сетям вне пределов контролируемой зоны;
          <br />
          - хранение материальных носителей персональных данных с соблюдением условий,
          обеспечивающих сохранность персональных данных и исключающих несанкционированный
          доступ к ним;
          <br />
          - осуществление внутреннего контроля соответствия обработки персональных данных
          Федеральному закону «О персональных данных» и принятым в соответствии с ним
          нормативным правовым актам, требованиям к защите персональных данных, настоящей
          Политике, локальным нормативным актам ПАО «ГК «САМОЛЕТ»;
          <br />
          - иные меры, предусмотренные законодательством Российской Федерации в области
          персональных данных.
          <br />
          11.2. Меры по обеспечению безопасности персональных данных при их обработке в
          информационных системах персональных данных устанавливаются в соответствии с
          локальными нормативными актами ПАО «ГК «САМОЛЕТ», регламентирующими вопросы
          обеспечения безопасности персональных данных при их обработке в информационных
          системах персональных данных ПАО «ГК «САМОЛЕТ».
          <br />
          12. Заключительные положения.
          <br />
          12.1. Настоящая Политика является общедоступным документом и подлежит размещению
          на официальном сайте ПАО «ГК «САМОЛЕТ».
          <br />
          12.2. Ответственность за нарушение требований законодательства Российской
          Федерации и нормативных документов ПАО «ГК «САМОЛЕТ» в области персональных
          данных определяется в соответствии с законодательством Российской Федерации.
        </p>
      </div>
    </Layout>
  );
};
