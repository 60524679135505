import { Layout } from '@shared/ui/layout';
import '@pages/consent/ui/index.css';

export const Consent = () => {
  return (
    <Layout>
      <div className='consent'>
        <h2 className='mb-32 title-40'>
          Согласие на обработку персональных данных (включая cookie)
        </h2>
        <p>
          1. Настоящим, свободно, своей волей и в своем интересе, добровольно,
          информировано и сознательно выражаю свое согласие на автоматизированную
          обработку моих персональных данных (далее – ПДн) ПАО «Группа компаний «Самолет»
          по адресу: Российская Федерация, 143082, Московская обл., Одинцовский г.о., г.
          Одинцово,д. Раздоры, ул. Липовой рощи, д. 1, к. 3, помещ. 18, ком. 3.
          <br />
          2. Обработка моих персональных данных может осуществляться в соответствии с
          перечнем персональных данных и целями, приведенными ниже:
          <br />
          <b>2.1 Категория субъектов персональных данных:</b>
          <br />
          Пользователи сайта и клиенты, заполнившие форму обратной связи <br />
          <b>2.2 Перечень персональных данных:</b>
          <br />
          -Ф.И.О.;
          <br />
          -контактный номер телефона;
          <br />
          -адрес электронной почты;
          <br />
          -компания
          <br />
          <b>2.3 Цели обработки:</b>
          <br />
          Обработка обращений и установление с Пользователем обратной связи, в том числе:
          <br />
          -Проведение презентации по продукту <br />
          3. Оператор вправе осуществлять обработку моих персональных данных следующими
          способами: сбор, запись, систематизация, накопление, хранение, уточнение
          (обновление, изменение), извлечение, использование, передача (предоставление,
          доступ), блокирование, удаление, уничтожение.
          <br />
          4. Субъект персональных данных согласен с тем, что в целях реализации условий
          договоров, заключенных между Субъектом персональных данных и Оператором, в том
          числе пользовательских соглашений и/или использования программного обеспечения,
          сервисов и услуг Оператора, а также в целях заключения субъектом персональных
          данных договоров с третьими лицами с использованием программного обеспечения,
          сервисов и услуг Оператора персональные данные могут быть переданы в полном
          объеме:
          <br />
          - Публичному акционерному обществу «Группа компаний «Самолет»; <br />
          - его аффилированным лицам актуальный и полный перечень которых размещён на
          сайте Центра раскрытия корпоративной информации; <br />
          - третьим лицам на основании заключенных Оператором с ними договоров, согласий
          Субъектов персональных данных (далее – третьим лицам), перечень которых размещен
          на сайте Оператора по ссылке. <br />
          4.1. Персональные данные Пользователей Сайта могут обрабатываться следующими
          интернет-сервисами: Яндекс.Метрика.
          <br />
          5. Пользователь уведомлен, что он, как субъект персональных данных, имеет право
          на получение информации, касающейся обработки его персональных данных. Права
          субъектов персональных данных приведены в Политике обработки персональных
          данных.
          <br />
          6. Субъект персональных данных вправе отозвать свое согласие на обработку
          персональных данных:
          <br />
          6.1. Отзыв согласия на обработку персональных данных может быть направлен
          Оператору в форме уведомления только с собственноручной подписью Субъекта
          персональных данных или с усиленной квалифицированной электронной подписью.
          <br />
          6.2. Уведомление об отзыве Субъектом персональных данных своего согласия на
          обработку персональных данных с собственноручной подписью Субъекта персональных
          данных направляется Оператору заказным письмом по адресу Публичного акционерного
          общества «Группа компаний «Самолет», указанному в Едином государственном реестре
          юридических лиц.
          <br />
          6.3. Уведомление об отзыве Субъектом персональных данных своего согласия на
          обработку персональных данных с усиленной квалифицированной электронной подписью
          Субъекта персональных данных направляется Оператору посредством системы
          электронного документооборота «Диадок».
          <br />
          6.4. В уведомлении об отзыве Субъектом персональных данных своего согласия на
          обработку персональных данных должны быть указаны полное наименование, адрес,
          ИНН, ОГРН Оператора, ФИО, адрес регистрации по месту жительства Субъекта
          персональных данных, а также явно выраженная воля на отзыв Субъектом
          персональных данных своего согласия на обработку персональных данных.
          <br />
          7. Персональные данные Субъекта персональных данных хранятся в течение всего
          срока действия пользовательских соглашений с данным Субъектом персональных
          данных, а также в течение 10 лет после прекращения их действия, если
          персональные данные не были отозваны лично Субъектом персональных данных.
          <br />
          8. Субъект персональных данных считается давшим настоящее Согласие в полном
          объеме без каких-либо изъятий с того момента, как он осуществил создание учётной
          записи Пользователя в программном обеспечении и/или сервисе, сайте Оператора,
          поставив отметку в специальном месте на странице создания учетной записи рядом
          со ссылкой на текст настоящего Согласия, а в отношении файлов cookies – с
          момента подтверждения в электронной форме.
          <br />
        </p>
      </div>
    </Layout>
  );
};
