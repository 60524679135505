import { SUBJECTS } from '@shared/config';
import { Layout } from '@shared/ui/layout';
import { ResearchJuneFrame } from '@shared/ui/researchJuneFrame';

export const ResearchJune = () => {
  return (
    <Layout subject={SUBJECTS.requestResearch}>
      <ResearchJuneFrame />
    </Layout>
  );
};
