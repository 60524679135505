import '@shared/ui/thirdFrame/index.css';

export const ThirdFrame = () => {
  return (
    <section className='third-frame mb-128'>
      <h2 className='title-48 mb-32 center-content'>Потенциал внедрения</h2>
      <p className='text-24 mb-96'>
        Результаты, которых мы достигли благодаря созданию <br />
        платформы «Самолет 10D»
      </p>
      <div className='process-wrapper'>
        <div className='process'>
          <div className='process-bar mb-8'></div>
          <div className='process-bar actual mb-48'></div>
          <h3 className='title-48 mb-16 center-content'>57%</h3>
          <p className='text-16'>
            рост <br />
            производительности
          </p>
        </div>
        <div className='process'>
          <div className='process-bar mb-8'></div>
          <div className='process-bar actual mb-48'></div>
          <h3 className='title-48 mb-16 center-content'>15%</h3>
          <p className='text-16'>
            сокращение <br />
            стоимости строительства
          </p>
        </div>
        <div className='process'>
          <div className='process-bar mb-8'></div>
          <div className='process-bar actual mb-48'></div>
          <h3 className='title-48 mb-16 center-content'>2 месяца</h3>
          <p className='text-16'>
            сокращение <br />
            сроков
          </p>
        </div>
      </div>
    </section>
  );
};
