import { FifthFrame } from '@shared/ui/fifthFrame';
import { FirstFrame } from '@shared/ui/firstFrame';
import { FourthFrame } from '@shared/ui/fourthFrame';
import { Layout } from '@shared/ui/layout';
import { SecondFrame } from '@shared/ui/secondFrame';
import { SixthFrame } from '@shared/ui/sixthFrame';
import { ThirdFrame } from '@shared/ui/thirdFrame';

export const Home = () => {
  return (
    <Layout>
      <FirstFrame />
      <SecondFrame />
      <ThirdFrame />
      <FourthFrame />
      <FifthFrame />
      <SixthFrame />
    </Layout>
  );
};
